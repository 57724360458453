import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { SimpleLoginService } from '../../services/auth/simple-login.service';
import { AdminAuthGuardService } from 'app/services/auth/adminAuth-guard.service';
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Search Device',  icon:'ni ni-tablet-button text-yellow', class: '' },
  { path: '/stat', title: 'Device Analytics',  icon:'ni-chart-bar-32 text-pink', class: '' },
  { path: '/deviceHistory', title: 'Device History',  icon:'ni-watch-time text-blue', class: '' },
  // { path: '/healthStatus', title: 'Apps Status',  icon:'ni-watch-time text-blue', class: '' }
];

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;
  public menuItems: any[];
  public isCollapsed = true;
  public isAdmin = false;
  username ='';
  constructor(location: Location,  private element: ElementRef, private router: Router,public simpleLogin: SimpleLoginService) {
    this.location = location;
  }

  ngOnInit() {
    this.username=localStorage.getItem('username');
    this.isAdmin=(localStorage.getItem('role')=='admin');
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }
    return 'Dashboard';
  }
  logout(){
    this.simpleLogin.logout();
  }

}
