import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class AdminAuthGuardService implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('role')=='admin') {
            // logged in so return true and has role admin

            return true;
        }

        // not logged in so redirect to login page with the return url
        //this.router.navigate(['/login']);

        return false;
    }
}