<nav class="navbar navbar-top navbar-expand-md navbar-dark bg-default" id="navbar-main">
  <div class="container-fluid">
    <!-- Brand -->
    <h4 class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" ><img alt="Image placeholder" src="assets/img/brand/logo-hqs01.png" style="width: 100px;"> </h4>
   <!-- Navigation -->
      <ul class="navbar-nav ">
          <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">              
              <a routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
                  <i class="ni {{menuItem.icon}}"></i>
                  {{menuItem.title}}
              </a>
          </li>
      </ul>
    <!-- User -->
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link pr-0" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="assets/img/theme/user.png">
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{username}}</span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <a *ngIf="isAdmin" routerLinkActive="active" [routerLink]="['/userAdmin']" class="dropdown-item">
            <i class="fa fa-users"></i>
            <span>Users administration</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/dashboard']" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </a>         
          <div class="dropdown-divider"></div>
          <a (click)="logout()" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
