import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, private router: Router) { }

  public searchUsers(): Observable<any> {
    const apiURL = '/api/v1/searchUsers';
    return this.http.get(apiURL);
  }
  public updateUser(userId,username,password,role,passwordNotChanged): Observable<any> {
    const apiURL = '/api/v1/updateUser';
    return this.http.post(apiURL,{id:userId,username:username,password: password,role:role,passwordNotChanged:passwordNotChanged});
  }
  public deleteUser(userId): Observable<any> {
    const apiURL = '/api/v1/deleteUser';
    return this.http.post(apiURL,{id:userId});
  }

}
