<!-- Sidenav -->


<!-- <app-sidebar></app-sidebar> -->
<div class="main-content">
  
  <!-- Top navbar -->
  <app-navbar></app-navbar>
  <!-- Pages -->
  <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" ><p style="color: white" > Loading... </p></ngx-spinner>
  <div class="container-fluid">
    <router-outlet></router-outlet>
  </div>
  <div class="container-fluid">
    <app-footer></app-footer>
  </div>
</div>
