import { finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";


@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    count = 0;
    constructor(private spinner: NgxSpinnerService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();
        this.count++;
        const idToken = localStorage.getItem("token");
        if (idToken) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization", idToken)
            });
            return next.handle(cloned).pipe(tap(
                event => console.log(event),
                error => console.log(error)
            ), finalize(() => {
                this.count--;
                if (this.count == 0) this.spinner.hide()
            })
            );
        }
        else {
            return next.handle(req).pipe(tap(
                event => console.log(event),
                error => console.log(error)
            ), finalize(() => {
                this.count--;
                if (this.count == 0) this.spinner.hide()
            })
            );
        }
    }
}